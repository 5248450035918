.list{
}

.list-row{
  list-style-type: none; /* Remove bullets */
}

.article-list-row{
  margin-top: 0;
  margin-bottom: 0; 
  padding-top: 0.5em;
  padding-bottom: 0;
}

.article-list-row.h2{
  margin-bottom: 0; 
  }
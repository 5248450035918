.cta-buttons{
    margin-top: 12px;
  }
  
  .button-today{
    width: 120px;
  }
  
  .flex-container{
    display: flex;
  }
  
  .flex-item{
    flex-grow: 1;
    margin-right: 12px;
  }
  
.main {
  margin-left: 180px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px 10px 10px;
  background-color: #dcdcdc;
  height: 100%;
  min-height: 100vh;
  width: calc(100vw - 180px);
}

select, textarea {
  background-color: white;
}



tr, th, td {
  text-align: left;
}
th{
  font-weight: bold;
}


/* .dashboard {

  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
} */
